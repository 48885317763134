<template>
	<div>
		<!-- 第一列 -->
		<el-form class="left" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="文章标题" prop="title">
				<el-input v-model="defForm.title"></el-input>
			</el-form-item>
			<el-form-item label="父级栏目" prop="channel_id">
				<treeselect v-model="defForm.channel_id" placeholder="请选择栏目" :multiple="false" :options="treeData" />
			</el-form-item>
			<el-form-item label="文章简述" prop="description">
				<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 5}" v-model="defForm.description">
				</el-input>
			</el-form-item>
		</el-form>
		<!-- 第二列 -->
		<el-form class="right" ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="文章图片" prop="image">
				<el-upload class="avatar-uploader" action="string" :show-file-list="false" :http-request="uploadImg"
					:before-upload="beforeAvatarUpload">
					<img v-if="defForm.image" :src="defForm.image" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="发布时间" prop="publish">
				<el-date-picker v-model="defForm.publish" type="date" value-format="yyyy-MM-dd HH:MM:SS"
					placeholder="请输入发布日期">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<!-- 编辑器 -->
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="120px">
			<el-form-item label="栏目内容" prop="content">
				<v-md-editor v-model="defForm.content" height="300px"></v-md-editor>
			</el-form-item>
		</el-form>
		<!-- 按钮部分 -->
		<div class="button" style="margin-left: 100px;">
			<el-button type="primary" @click="$router.back()">返回</el-button>
			<el-button type="primary" :loading="loading" @click="submitForm(defForm)">保存</el-button>
		</div>
	</div>
</template>
<script>
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import {update,upload,detail} from '@/api/article'
	import {tree} from '@/api/channel'
	import token from '@/token.js'
	export default {
		name: "Add",
		components: {
			Treeselect
		},
		data() {
			return {
				defForm: {
					id:0,
					title: '',
					channel_id: null,
					description: '',
					image: '',
					publish: '',
					content: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				treeData: [],
				loading: false,
				rules: {
					title: [{
						required: true,
						message: '必填项不能为空'
					}],
					channelId: [{
						required: true,
						message: '必填项不能为空'
					}],
				}
			}
		},

		created() {
			let article = this.$route.query.article
			console.log(article)
			this.defForm.id=article.id,
			this.defForm.title=article.title,
			this.defForm.channel_id=article.channel_id,
			this.defForm.description=article.description,
			this.defForm.image=article.image,
			this.defForm.publish=article.publish,
			this.defForm.content=article.content==null ? '': article.content,
			// console.log(this.defForm)
			tree().then(data => {
				this.treeData = data.data
			}).catch(error => {
				this.$message.error("树级栏目获取失败！")
			})
		},
		methods: {
			submitForm(form) {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						if(this.defForm.channel_id==null){
							this.defForm.channel_id=0;
						}
						// console.log("新增",this.defForm)
						update(this.defForm).then(data => {
							this.loading = false
							this.$refs['defForm'].resetFields()
							this.$message.success("文章编辑成功!")
							this.$router.push('/admin/news/articleindex')
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			},
			uploadImg(param) {
				let formData = new FormData();
				formData.append("file", param.file)
				upload(formData).then(data => {
					this.defForm.image = data.data
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//上传之前的验证
			beforeAvatarUpload(file) {
				const isIMAGE = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				if (!isIMAGE) {
					this.$message.error('上传文件只能是图片格式!')
				}
				return isIMAGE 
			},
		}
	}
</script>

<style scoped>
	.left{
		width: 50%;
		display: inline-block;
	}
	.right{
		width: 50%;
		display: inline-block;
	}
	.avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		width: 80px;
		height: 80px;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	.avatar {
		width: 80px;
		height: 80px;
		display: block;
	}
</style>
